import {
  IBuyRECsForm,
  IRenewableOptionsTotal,
  IRenewableParameters,
  IRenewableToBuyParameters,
} from "@/models/RenewableOptions";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const RENEWABLE_OPTIONS = "RenewableOptions";

export class RenewableOptionAPI extends HttpClient {
  constructor() {
    super(process.env.VUE_APP_DOMAIN_API_SERVER);
  }

  public getRenewableOptions = async (
    params: IRenewableParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(RENEWABLE_OPTIONS, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getBuyList = async (
    params: IRenewableToBuyParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/buy`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getEnergyType = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/enumEnergies`)
      .catch(catchAxiosError);

    return response;
  };

  public getStandards = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/enumSustainabilityStandards`)
      .catch(catchAxiosError);

    return response;
  };

  public getEWallet = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/eWallet`)
      .catch(catchAxiosError);

    return response;
  };

  public submitBuyRECs = async (data: IBuyRECsForm): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${RENEWABLE_OPTIONS}/buy`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getRenewableOptionsTotal = async (
    data: IRenewableOptionsTotal
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/${data.Year}/total`)
      .catch(catchAxiosError);

    return response;
  };
}
