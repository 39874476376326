import { IPagination } from "@/models/Pagination";
import {
  IBuyRECsForm,
  IEnergyType,
  IRenewableDTO,
  IRenewableParameters,
  IRenewableToBuyDTO,
  IRenewableToBuyParameters,
  IStandards,
} from "@/models/RenewableOptions";
import { ErrorResponse } from "@/services/axios/error";
import { RenewableOptionAPI } from "@/services/renewableoptions";
import store from "@/store";
import { addUniqueKeytoItem } from "@/utils/helpers/buildDataFormat";
import { handlePagination } from "@/utils/helpers/paginateData";
import { MAX_RECS, PAGE_SIZE_BUY_RECS } from "@ems/constants";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

const name = "RenewableOptionsModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class RenewableOptionsModule extends VuexModule {
  private errorRenewAbleOption = {} as ErrorResponse;
  private errorRenewableBuyList = {} as ErrorResponse;
  private errorEnergyType = {} as ErrorResponse;
  private errorStandardsType = {} as ErrorResponse;
  private errorEWallet = {} as ErrorResponse;
  private errorBuyRECs = {} as ErrorResponse;

  private parameterRenewableOptions = {} as IRenewableToBuyParameters;
  private renewAbleOptions = [] as IRenewableDTO[];
  private isLoadingRenewAbleOption = false;
  private shoppingCart = [] as IRenewableToBuyDTO[];
  private eWallet = 0;

  private buyListPagination = {} as IPagination;
  private renewableBuyList = [] as IRenewableToBuyDTO[];
  private isLoadingRenewableBuyList = false;
  private pagination = {} as IPagination;
  private tempRenewableBuyList = [] as IRenewableToBuyDTO[];

  private energyType = [] as IEnergyType[];
  private standardsType = [] as IStandards[];

  private isLoadingBuyRECs = false;

  get buyListPaginationGetter() {
    return this.buyListPagination;
  }
  get isRenewAbleOptionGetter() {
    return this.isLoadingRenewAbleOption;
  }
  get renewAbleOptionsGetter() {
    return this.renewAbleOptions;
  }
  get isLoadingRenewAbleOptionGetter() {
    return this.isLoadingRenewAbleOption;
  }

  get renewableBuyListGetter() {
    return this.renewableBuyList;
  }
  get isLoadingRenewableBuyListGetter() {
    return this.isLoadingRenewableBuyList;
  }
  get shoppingCartGetter() {
    return this.shoppingCart;
  }
  get eWalletGetter() {
    return this.eWallet;
  }

  get energyTypeGetter() {
    return this.energyType;
  }

  get standardsTypeGetter() {
    return this.standardsType;
  }

  get parameterRenewableOptionsGetter() {
    return this.parameterRenewableOptions;
  }

  get isLoadingBuyRECsGetter() {
    return this.isLoadingBuyRECs;
  }
  @Mutation
  setLoadingRenewAbleOption(isLoading: boolean) {
    this.isLoadingRenewAbleOption = isLoading;
  }
  @Mutation
  getRenewableOptionError(error: ErrorResponse) {
    this.errorRenewAbleOption = error;
    this.isLoadingRenewAbleOption = false;
  }

  @Mutation
  getRenewableOptionSuccess(data: any) {
    if (!isEmpty(data)) {
      const { Collection, ...rest } = data;
      this.renewAbleOptions = Collection;
      this.pagination = rest;
      this.errorRenewAbleOption = {} as ErrorResponse;
    } else {
      this.renewAbleOptions = [];
    }

    this.isLoadingRenewAbleOption = false;
  }
  @Action
  async getRenewableOptionsAction(payload: IRenewableParameters) {
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    this.setLoadingRenewAbleOption(true);
    const { data, error } = await serviceRenewableOptionAPI.getRenewableOptions(
      payload
    );
    if (error) {
      this.getRenewableOptionError(error);
    } else {
      this.getRenewableOptionSuccess(data);
    }
  }

  @Mutation
  setLoadingBuyList(isLoading: boolean) {
    this.isLoadingRenewableBuyList = isLoading;
  }
  @Mutation
  getBuyListError(error: ErrorResponse) {
    this.errorRenewableBuyList = error;
    this.isLoadingRenewableBuyList = false;
  }
  @Mutation
  getBuyListSuccess(data: IRenewableToBuyDTO[]) {
    this.tempRenewableBuyList = data;
    // const dataWithUniqueKey = addUniqueKeytoItem(data);

    this.buyListPagination = {
      CurrentPage: 1,
      Limit: PAGE_SIZE_BUY_RECS,
      TotalRow: data.length,
      TotalPage: Math.ceil(data.length / PAGE_SIZE_BUY_RECS),
    };

    this.renewableBuyList = handlePagination(
      data,
      this.buyListPagination.Limit,
      this.buyListPagination.CurrentPage,
      this.buyListPagination.TotalRow
    );
    this.isLoadingRenewableBuyList = false;
    this.errorRenewableBuyList = {} as ErrorResponse;
  }
  @Mutation
  onChangePage(currentPage: number) {
    this.buyListPagination = {
      ...this.buyListPagination,
      CurrentPage: currentPage,
    };

    this.renewableBuyList = handlePagination(
      this.tempRenewableBuyList,
      this.buyListPagination.Limit,
      this.buyListPagination.CurrentPage,
      this.buyListPagination.TotalRow
    );
  }
  @Action
  async getBuyListAction(payload: IRenewableToBuyParameters) {
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    this.setLoadingBuyList(true);
    this.emptyBuyList();
    const { data, error } = await serviceRenewableOptionAPI.getBuyList(payload);
    if (error) {
      this.getBuyListError(error);
    } else {
      this.getBuyListSuccess(data);
      this.saveParameterRenewableOptions(payload);
    }
  }
  @Mutation
  emptyBuyList() {
    this.renewableBuyList = [];
  }
  @Mutation
  saveParameterRenewableOptions(data: IRenewableToBuyParameters) {
    this.parameterRenewableOptions = data;
  }

  @Mutation
  addToShoppingCart(item: IRenewableToBuyDTO) {
    if (this.shoppingCart.length < MAX_RECS) {
      this.shoppingCart.push({
        ...item,
        __MAX_AMOUNT: item.Amount,
      });
    } else {
      ElMessage({
        message: "Limited up to 5",
        grouping: true,
        type: "warning",
      });
    }
  }
  @Mutation
  removeToShoppingCart(item: IRenewableToBuyDTO) {
    const idx = this.shoppingCart.findIndex(
      (el) =>
        `${el.Id}${el.Name}${el.Year}` === `${item.Id}${item.Name}${item.Year}`
    );
    if (idx !== -1) {
      this.shoppingCart.splice(idx, 1);
    }
  }
  @Mutation
  updateShoppingCart(item: IRenewableToBuyDTO) {
    const idx = this.shoppingCart.findIndex(
      (el) =>
        `${el.Id}${el.Name}${el.Year}` === `${item.Id}${item.Name}${item.Year}`
    );
    if (idx !== -1) {
      this.shoppingCart[idx] = item;
    }
  }
  @Mutation
  emptyShoppingCart() {
    this.shoppingCart = [] as IRenewableToBuyDTO[];
  }

  @Mutation
  getEnergyTypeError(error: ErrorResponse) {
    this.errorEnergyType = error;
  }
  @Mutation
  getEnergyTypeSuccess(data: IEnergyType[]) {
    data.unshift({ Value: null, Name: "All" });
    this.energyType = data;
    this.errorEnergyType = {} as ErrorResponse;
  }
  @Action
  async getEnergyTypeAction() {
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    const { data, error } = await serviceRenewableOptionAPI.getEnergyType();
    if (error) {
      this.getEnergyTypeError(error);
    } else {
      this.getEnergyTypeSuccess(data);
    }
  }

  @Mutation
  getEWalletSuccess(number: number) {
    this.eWallet = number;
    this.errorEWallet = {} as ErrorResponse;
  }
  @Mutation
  getEWalletError(error: ErrorResponse) {
    this.errorEWallet = error;
    this.eWallet = 0;
  }
  @Action
  async getEWallet() {
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    const { data, error } = await serviceRenewableOptionAPI.getEWallet();

    if (error) {
      this.getEWalletError(error);
    } else {
      this.getEWalletSuccess(data);
    }
  }

  @Mutation
  getStandardsError(error: ErrorResponse) {
    this.errorStandardsType = error;
  }
  @Mutation
  getStandardsSuccess(data: IStandards[]) {
    this.standardsType = data;
    this.errorStandardsType = {} as ErrorResponse;
  }
  @Action
  async getStandardsAction() {
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    const { data, error } = await serviceRenewableOptionAPI.getStandards();
    if (error) {
      this.getStandardsError(error);
    } else {
      this.getStandardsSuccess(data);
    }
  }
  @Mutation
  setLoadingBuyRECs(isLoading: boolean) {
    this.isLoadingBuyRECs = isLoading;
  }
  @Mutation
  submitBuyRECsSuccess() {
    this.errorBuyRECs = {} as ErrorResponse;
    this.isLoadingBuyRECs = false;
  }
  @Mutation
  submitBuyRECsError(error: ErrorResponse) {
    this.errorBuyRECs = error;
    this.isLoadingBuyRECs = false;
  }
  @Action
  async submitBuyRECs(data: IBuyRECsForm): Promise<boolean> {
    this.setLoadingBuyRECs(true);
    const serviceRenewableOptionAPI = new RenewableOptionAPI();
    const { error } = await serviceRenewableOptionAPI.submitBuyRECs(data);

    if (error) {
      this.submitBuyRECsError(error);
      return false;
    } else {
      this.submitBuyRECsSuccess();
      return true;
    }
  }
}
export default getModule(RenewableOptionsModule);
