export const handlePagination = (
  data: any[],
  limit: number,
  currentPage: number,
  total: number
) => {
  const startIndex = (currentPage - 1) * limit;
  const endIndex = Math.min(startIndex + limit - 1, total - 1);
  return data.slice(startIndex, endIndex + 1);
};
